body{
  --bg: #f7f7f7;
  --gray1: #1c1c1c;
  --gray2: #3C3C3C;
  --gray3: #494949;
  --gray4: #c7c7c7;
  --gray5: #fff;
  --gradient1: rgb(151, 218, 251);
  --gradient2: rgb(109, 131, 255);
  //--tag1-bg: rgb(241, 239, 255);
  --tag1: rgb(158, 136, 255);
  //--tag2-bg: #eef5ff;
  --tag2: #69a9fd;
  //--tag3-bg: rgb(236, 248, 255);
  //--tag3: rgb(35, 204, 255);
  --tag3: #5cdbc6;

  background-color: var(--bg);

  -webkit-text-size-adjust: 100%;

}
  


.app{

  .blue {
    background: --var(--gradient1);
    background: linear-gradient(-190deg, var(--gradient1) 0%, var(--gradient2) 100%); 
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  a{
    text-decoration: none;
    color: inherit;
  }

  a:hover{
    text-decoration: underline;
    text-decoration-color: var(--gradient2);
  }

  b{
    font-weight: 400;
  }

  font-size: 12px;
  font-weight: 200;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: fit-content;


  .resume{
    display: block;
    margin: 2% auto;
    width: 21cm;
    height: 29.7cm; 
    overflow: none;
    background-color: var(--gray5) ;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .body{
      padding: 2% 5%;

      .header{
        width: 100%;
        height: 3cm;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin-bottom: 0px;

        .text{

          h3{
            font-size: 45px;
            font-weight: 600;
          }

          p{
            font-size: 17px;
            font-weight: 300;
            color: var(--gray2);
          }
        }



        .socials{
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;

          a{
            width: fit-content;
            display: flex;
            flex-direction: row;

            i{
              font-size: 13px;
              margin-right: 3px;
            }

            p{
              font-size: 11px;
              font-weight: 400;
            }
          }

        }
      }

      .sections{
        margin-top: 0px;

        > *{
          margin-top: 10px;
        }

        h2{
          font-size:18px;
          font-weight: 400;
        }

        .experience{
          display: flex;
          margin-top: 13px;

          .logo{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 13%;
          }

          .text{
            width: calc(100% - 13%);
            padding-left: 5px;

            .intro{
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 2.5px;

              #title{
                font-size: 14px;
                b{
                  font-weight: 500;
                }
                 a{
                   font-weight: 400;
                 }
              }

              #info{
                font-size: 11px;
                color: var(--gray3);
              }
            }

            .description{
              text-align: justify;
              ul{
                padding-left: 17px;

                li{
                  padding-bottom: 4px;
                }
              }
            }
          }
        }

        .education{
          display: flex;
          flex-direction: column;
          margin-top: 10px;

          .intro{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 3px;

            #title{
              font-size: 14px;
                a{
                  font-weight: 400;
                }
            }

            #info{
              margin-top: 3px;
              font-size: 11px;
              color: var(--gray3);
            }
          }

          .description{
            text-align: justify;
            ul{
              padding-left: 17px;

              li{
                padding-bottom: 1px;
              }
            }
          }
        }

        .projects{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          height: 4.8cm;

          .project{
            margin-top: 10px;
            width: 30%;
            height: 40%;
            text-align: justify;

            #title{
              font-size: 14px;
              font-weight: 400;
            }

            #description{
              margin-top: 2px;
            }
          }
        }

        .section-skills{
          margin-top: 10px;

          .tag{
            font-size: 10px;
            font-weight: 500;
            margin-top: 3px;
            margin-right: 5px;
            padding: 3px 3px;
            border-radius: 4px;

            &.preferred{
              color: var(--tag1);
              border: var(--tag1) 1px solid;
              background-color: var(--tag1-bg);
            }

            &.knowledge{
              color: var(--tag2);
              border: var(--tag2) 1px solid;
              background-color: var(--tag2-bg);
            }

            &.interested{
              color: var(--tag3);
              border: var(--tag3) 1px solid;
              background-color: var(--tag3-bg);
            }
          }

          .intro{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .indications{
              display: flex;
              flex-direction: row;
            }
          }

          .skills{
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
            height: 3cm;

            > *{
              width: fit-content;
              margin: 5px 0px;
              
              h4{
                font-size: 12px;
                font-weight: 300;
              }
            }

            .tags{
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .web{
              margin-left: auto;  // ensures the web part aligns to flex-end
            }
          }
        }

      }

      
    }

  }

  @media print {
    body * {
      visibility: hidden;
    }
    #section-to-print, #section-to-print * {
      visibility: visible;
      .blue {
        background: none #fff;
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: var(--tag2);
        color:rgb(151, 218, 251);
      }
    }
    #section-to-print {
      position: absolute;
      left: 0;
      top: -15px;
    }
  }

}